<template>
  <div class="layout">
    <HeaderBar></HeaderBar>
    <div class="layout_container">
      <div class="layout_container_view">
       <el-card>
           <div id="main-container">
            <router-view />
           </div>
         <FooterBar></FooterBar>
       </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/views/layout/HeaderBar";
import FooterBar from "@/views/layout/FooterBar";
export default {
  name: "MainLayout",
  components: {HeaderBar, FooterBar},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang='scss' >
.soulexe-footer {
  margin-top:2em;
  text-align: center;
  bottom: 0;
  height:auto;
  font-size:9px;
  color:var(--gray-color7);
  p {
    text-align: center;
  }
  a {
    color: var(--gray-color7);
    text-decoration: none;
  }
}
.pager{
  margin-top: 20px;
}
.mark-title{
  margin: 0 0 20px 0;
  color: var(--scheme-color5);
}
#main-container{
  min-height:calc(100vh - 165px);
}
</style>