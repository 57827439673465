import axios from "axios";
import router from '@/router'
import {ElMessage} from "element-plus";

// 异常返回统一处理，401直接跳登录页
function errorHandle(error, onError){
    if (error.response !== undefined && error.response.status === 401) {
        ElMessage({
            showClose: true,
            message: '登录认证超时，请重新登录!',
            type: 'warning',
        })
        router.push('/login').then()
    } else {
        onError(error)
    }
}

// 返回处理，先检查错误码，不为0统一提示
function resHandle(response, callback){
    console.log(response)
    if (typeof(response.data.code) == 'undefined') {
        ElMessage({
            showClose: true,
            message: response.data.msg,
            type: 'warning',
        })
        return false;
    } else if (response.data.code === 2){
        ElMessage({
            showClose: true,
            message: '未登录或登录已过期，请重新登录!',
            type: 'warning',
        });
        location.href = '/login';
    } else if (response.data.code !== 0){
        ElMessage({
            showClose: true,
            message: response.data.msg,
            type: 'warning',
        })
    } else {
        callback(response.data.result)
    }
}

const Service = {
    request(url, params={}, callback, method='GET', onError){
        // 默认错误处理
        if (typeof(onError) != 'function') {
            onError = function(error) {
                console.log(error)
            }
        }
        if (method === 'POST') {
            axios.post(url, params)
                .then(function (response){
                    resHandle(response, callback)
                })
                .catch(function (error){
                    errorHandle(error, onError)
                })
        } else {
            axios.get(url,{params: params})
                .then(function (response){
                    resHandle(response, callback)
                })
                .catch(function (error){
                    errorHandle(error, onError)
                })
        }
    }
}

export default Service
