<template>
    <router-view></router-view>
</template>

<script>
</script>

<style>
html,body,#app {
  margin:0;
  border:0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

