<template>
  <div id="chat-box">
    <div id="chat-board">
        <el-card class="box-card" v-for="item in msgList" :key="item">
            <template #header>
                <div class="card-header">
                    <span>{{item.user}}</span>
                </div>
            </template>
            <pre>
{{item.msg}}
            </pre>
        </el-card>
        <el-card class="box-card" v-loading="true" v-show="loading"></el-card>
    </div>
  </div>
  <div id="chat-input">
  <div id="chat-form">
    <el-form :inline="true" :model="searchForm" class="chat-input-form">
        <el-form-item label="">
        <el-select style="width:100px;" v-model="searchForm.model">
            <el-option label="chatgpt" value="chatgpt" />
            <el-option label="百度" value="baidu" />
        </el-select>
        </el-form-item>
        <el-form-item label="">
            <el-input placeholder="输入聊天内容" :rows="3" type="textarea" v-model="searchForm.msg" style="width:500px;"/>
        </el-form-item>
        <el-form-item>
        <el-button type="success" @click="sendMsg" :loading="loading">发送聊天</el-button>
        <el-button type="danger" @click="reset" :loading="loading">重置对话</el-button>
        </el-form-item>
    </el-form>
  </div>
  </div>
</template>

<script>
import Service from "@/util/request";

export default {
  name: "WelcomePage",
  data(){
    return {
        searchForm:{
            model: 'chatgpt',
            msg: '',
            session: ''
        },
        msgList:[],
        loading: false
    }
  },
  methods: {
    reset(){
        if (confirm("确定要清空对话吗？")){
            this.searchForm.session = ''
            this.msgList = []
        }
    },
    sendMsg(){
        let msg = this.searchForm.msg
        this.msgList.push({
          msg: msg,
          user: '我'
        })
        if (this.searchForm.model == 'chatgpt') {
          let prompt = ''
          for (let i in this.msgList){
            if (this.msgList[i]['user'] == '我'){
              prompt += "Human:" + this.msgList[i]['msg'] + "\n"
            } else {
              prompt += "AI:" + this.msgList[i]['msg'] + "\n"
            }
            msg = prompt
          }
        }
        this.loading = true
        this.autoScroll()
        let  params = {
            msg: msg,
            model: this.searchForm.model,
            session_id: this.searchForm.session
        }
        let _this = this
        Service.request('/api/tool/chat', params, function (data) {
            _this.searchForm.session = data.session_id
            _this.searchForm.msg = ''
            let msg = data.msg.replace('AI:', '').replace('Robot:', '')

            _this.msgList.push({
              msg: msg,
              user: params.model
            })
            _this.loading = false
            _this.autoScroll()
        }, 'POST')
    },
    autoScroll(){
        let element = document.getElementById('chat-board')
        let i = 1
        function main() {
            if (element.scrollTop + element.clientHeight == element.scrollHeight) {
                clearInterval(interval)
                console.log('已经到底部了')
            } else {
                element.scrollTop += 300;
                console.log(i);
            i    += 1;
            }
        }
        // 定义ID
        let interval = setInterval(main, 50)
    }
  },
}
</script>

<style lang="scss" scoped>
body{
    overflow:hidden;
}
#chat-box{
    position:relative;
    width:100%;
    height:calc(100% - 100px);
}
#chat-board{
    width:80%;
    height:100%;
    overflow-y:scroll;
    margin:0 auto;
}
#chat-input{
    position:relative;
    width:100%;
    height:100px;
    background-color: #444;
}
#chat-form{
    width:80%;
    height:100%;
    margin:0 auto;
}
.chat-input-form{
    padding-top: 15px;
    margin-left: 20px;
}
.box-card{
    margin:0 10px 10px 10px;
    background: #333;
    color: #fafafa;
    border: 1px solid #141414;
    border-radius: 10px;
}
.el-card__header {
    border-bottom: 1px solid #666;
}
.el-loading-mask {
    background: #333;
}
</style>