<template>
  <el-footer class="soulexe-footer">
    <p>
      <a href="https://beian.miit.gov.cn">粤ICP备2023097332号</a>
    </p>
  </el-footer>
</template>

<script >
export default {
  name: "FooterBar",
  components: {}
};
</script>

<style lang="scss">
.flex-grow {
  flex-grow: 1;
}
</style>